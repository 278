<template>
  <form id="collect-form">
    <div class="widgetContainer bg-white widgetContainer--scrollable debitPull">
      <div class="navigationHeader">
        <div class="navigationHeader__inner">
          <p class="title">
            {{ $t('RCD_debitPull') }}
          </p>
          <span class="el-icon-close" @click="drawerClose" />
        </div>
        <div class="debit-pull-amount">
          <p class="font-12">
            * {{ $t('RCD_debitPull_rule1') }}
          </p>
          <p class="font-12">
            * {{ $t('RCD_debitPull_rule2') }}
          </p>
        </div>
      </div>

      <div class="widgetContainer__body">
        <el-form
          class="wise-form"
          ref="cardDetail"
          :model="formData"
          :rules="formRules">
          <el-form-item
            prop="amount"
            :error="errorAmount ? $t('RCD_debitPull_exceeded_amount') : null"
            :label="$t('debitPull_amount_label')">
            <Amount
              :precision="2"
              v-model="formData.amount"
              :validate-status="'error'"
              @input="checkAmount"
              :rules="formRules.amount"
              :masked="true" />
          </el-form-item>

          <el-form-item
            prop="description"
            :label="$t('debitPull_amount_description')">
            <div class="el-input">
              <textarea name="description" v-model="formData.description" class="el-input__inner" />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="el-drawer__footer">
        <el-button data-testid="submit" type="primary" @click="next" class="el-button__brand brand width-100" :disabled="!isFormValid">
          {{ $t('next') }}
        </el-button>
      </div>
    </div>
  </form>
</template>

<script>
import plaid from '../mixins/plaid';
import {mapActions, mapGetters} from 'vuex';
import Amount from '@/components/Amount';

export default {
  mixins: [plaid],
  components: {
    Amount
  },
  data() {
    return {
      formData: {
        amount: '',
        description: '',
      },
      errorAmount: false,
      formRules: {
        amount: [
          {
            required: true,
            message: 'Address 1 is required'
          }
        ],
      }
    };
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelectedContactData']),
    isFormValid() {
      return (
        this.formData.amount !== '' && (this.formData.amount > 0 && this.formData.amount <= 500)
      );
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    ...mapActions('fund', ['debitPull']),
    checkAmount: function(e) {
      const value = parseFloat(e);
      this.errorAmount = value > 500;
    },
    next() {
      const loader = this.showLoader();
      const payload = {
        accountId: this.getSelectedAccount.id,
        contactId: this.getSelectedContactData.id,
        amount: this.formData.amount,
        description: this.formData.description
      };

      this.debitPull(payload).then(() => {
        loader.close();
        this.$emit('goTo', 'DebitPull-success');
      }, (error) =>  {
        this.apiErrorMessage(error);
        this.drawerClose();
        loader.close();
      });
    }
  }
};
</script>

<style lang="scss">
.wise-form .el-form-item__label {
  background: inherit;
}

.debit-pull-amount {
  margin-top: 10px;
  padding-left: 19px;
}

textarea {
  width: 100%!important;
  max-width: 100%!important;
}
</style>
